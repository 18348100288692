import React from 'react';
import {Outlet} from "react-router-dom";
import {Header} from "./shared/header";
import './index.css'
function App() {
  return (
      <div className={'dark:bg-gray-900 h-screen w-screen overflow-auto'}>
          <Outlet/>
      </div>
  );
}

export default App;
