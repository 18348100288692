import logo from "../assets/tollesoft.png";
import {useNavigate} from "react-router-dom";

export const Footer = () => {
    const navigate = useNavigate();
    const route = (url: string) => {
        navigate(url);
    }
    const scrollToWhatWeDO = () => {
        const element = document.getElementById('whatWeDo');
        if (element) {
            element.scrollIntoView({behavior: "smooth"})
        }
    }

    const scrollToOurProcess = () => {
        const element = document.getElementById('OurProcess');
        if (element) {
            element.scrollIntoView({behavior: "smooth"})
        }
    }

    const scrollToAboutUs = () => {
        const element = document.getElementById('AboutUs');
        if (element) {
            element.scrollIntoView({behavior: "smooth"})
        }
    }
  return (
      <div className={'h-auto w-full bg-gray-700/20'}>
          <div className="grid lg:grid-cols-3 gap-4 h-auto relative w-full py-16 px-2">
              <div className="flex justify-center items-center space-x-1 dark:text-white">
                  <a className="font-extrabold">
                      <img src={logo} style={{ maxWidth: '200px' }} alt={''} />
                      <span className={'block'}>Innovating for a better tomorrow.</span>
                  </a>
              </div>
              <div className={'flex lg:justify-center max-lg:pb-10'}>
                  <div className="space-y-2 dark:text-white">
                      <span className={'text-xl font-bold dark:text-gray-400'}>Get Intouch With Us</span>
                      <span className="cursor-pointer block">(+233)24 886 4963</span>
                      <span className="cursor-pointer block">(+233)54 295 5456</span>
                      <span className="cursor-pointer block text-xs">Email</span>
                      <span className="cursor-pointer block">osaba78@gmail.com</span>
                      <span className="cursor-pointer block">bernard.ossei@gmail.com</span>
                  </div>
              </div>

             <div className={''}>
                 <h1 onClick={() => route('/')} className={'text-2xl max-lg:pb-5'}>Home</h1>
                 <h1 className={'text-2xl max-lg:pb-5'}>Products</h1>
                 <div className="space-y-2 dark:text-white">
                     <div onClick={() => route('/fidbak')} className={'px-2'}>FID BAK</div>
                     {/*<div className={'px-2'}>TOLLECARE</div>*/}
                 </div>
             </div>
          </div>
          <span className={'block w-full text-center pb-4'}> Copyright &copy; {new Date().getFullYear()} Tollesoft LTD. All Rights Reserved. Made with ❤️ using ReactJS and TailwindCSS.</span>
      </div>
  )
}
