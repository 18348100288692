import teamImg from '../assets/team.jpg';
import csd from '../assets/csd.jpg';
import {Footer} from "../shared/footer";
import React, {useEffect} from "react";
import {Header} from "../shared/header";
export default function Home() {

    useEffect(() => {
        const element = document.getElementById('top');
        if (element) {
            element.scrollIntoView({behavior: "smooth"})
        }
    }, [])

    return (
        <>
            <Header/>
            <div id={'top'} className="flex flex-col w-full h-full border-red-400">
                <div className={'flex justify-center w-full'}>
                    <div className="max-xl:w-full xl:w-10/12 2xl:w-4/6 space-y-4">
                        <div className={'lg:grid lg:grid-cols-2 lg:gap-4 h-full relative w-full py-16 lg:px-2'}>
                            <div className={'px-4 max-lg:pb-6'}>
                                <h1 className={'dark:text-gray-400 text-slate-600 text-6xl font-extrabold pb-4 max-lg:text-center max-lg:text-5xl'}>Transforming your ideas into digital reality</h1>
                                <p className={'dark:text-gray-100 leading-8 text-gray-600 text-xl max-lg:text-center'}>
                                    We improve your work and increase productivity by building software that addresses specific needs and goals of your organization.
                                </p>
                            </div>
                            <div className={'max-lg:flex max-lg:justify-center'}>
                                <div className={'relative'}>
                                    <div className={'relative rounded-2xl max-lg:w-[300px] overflow-auto z-[1] rotate-6'}>
                                        <div className={'absolute inset-0 bg-gray-800/30 '}></div>
                                        <img src={teamImg} className={'max-w-full h-auto'} alt={''}/>
                                    </div>
                                    <div className={'absolute max-lg:w-[300px] inset-0 dark:bg-gray-800 bg-gray-400 h-full w-full rounded-2xl'}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'dark:bg-gray-700/20 bg-gray-900/60 h-auto w-full flex items-center'}>
                    <div className={'flex justify-center w-full px-4 py-36'}>
                        <h1 className={'text-2xl lg:w-3/6 max-lg:w-full text-center text-white dark:text-gray-200'}>
                            We specialize in delivering innovative,
                            high-quality software solutions that empower businesses to achieve their goals.
                            Our team of expert developers, designers, and project managers work closely with clients to understand their
                            unique needs and deliver customized solutions that drive success.
                            Whether you're looking to build a new application or optimize an existing one, we're here to help.
                            Explore our services and learn more about how we can help you achieve your goals.
                        </h1>
                    </div>
                </div>
                <div className={'flex justify-center w-full py-10'} id={'whatWeDo'}>
                    <div className="max-xl:w-full xl:w-10/12 2xl:w-4/6 space-y-4">
                        <div className={'grid lg:grid-cols-2 gap-10 h-full relative w-full py-16 px-2'}>
                            <div className={'max-lg:flex max-lg:justify-center'}>
                                <div className={'relative'}>
                                    <div className={'relative max-lg:w-[300px] overflow-auto z-[1] ml-4 -mr-4'}>
                                        <div className={'absolute max-w-full inset-0 bg-gray-800/30 rounded-2xl'}></div>
                                        <img src={csd} className={'max-w-full h-auto rounded-2xl'} alt={''}/>
                                    </div>
                                    <div className={'absolute left-0 top-0 right-0 bottom-3 max-w-full mt-4 mr-4 bg-gray-800 h-full w-full'}></div>
                                </div>
                            </div>
                            <div>
                                <h1 className={'dark:text-gray-400 text-slate-600 text-4xl max-lg:text-2xl max-lg:text-center font-extrabold pb-4'}>- Custom Software Development</h1>
                                <p className={'dark:text-gray-100 leading-8 text-xl'}>
                                    We specialize in custom software development,
                                    creating tailored solutions to meet the unique needs of each of our clients.
                                    Our experienced team of developers takes the time to understand the specific challenges and goals of your organization,
                                    and then works closely with you to design and build a custom software solution that addresses those needs.
                                    Whether you're looking to improve your operations, streamline your workflow, or develop a new product,
                                    we have the expertise and experience to help you achieve your goals.
                                    With our custom software development services,
                                    you can trust that you're getting a solution that is tailored to your specific needs and is designed to help you succeed.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div id={'OurProcess'} className={'flex justify-center w-full py-10 dark:bg-gray-700/20 bg-gray-900/60 text-white h-auto'}>
                    <div className="max-xl:w-full xl:w-10/12 2xl:w-4/6 space-y-4 py-20">
                        <h1 className={'dark:text-gray-400 text-4xl font-extrabold pb-4 w-full text-center'}>- Our Process</h1>
                        <div className={'grid lg:grid-cols-2 gap-10 h-full relative w-full py-16 px-2'}>
                            <div>
                                <h1 className={'dark:text-gray-400 text-2xl font-extrabold pb-4 w-full text-center'}>- Requirement Gathering</h1>
                                <p className={'dark:text-gray-100 leading-8 text-xl text-center'}>
                                    At tollesoft, we begin the our software development process by gathering detailed requirements from our clients.
                                    This includes understanding the specific goals and objectives of the project,
                                    as well as any constraints or limitations that may affect the development process.
                                    We sometimes conduct user research and gather feedback to ensure that the final product meets the needs of the end-users.
                                </p>
                            </div>
                            <div>
                                <h1 className={'dark:text-gray-400 text-2xl font-extrabold pb-4 w-full text-center'}>- Sprint Planning</h1>
                                <p className={'dark:text-gray-100 leading-8 text-xl text-center'}>
                                    Once we have a clear understanding of the requirements, we plan out the development process in sprints. During sprint planning,
                                    we break down the project into smaller, manageable tasks and assign them to different team members.
                                    We also establish clear goals and deadlines for each sprint to ensure that we are making progress towards the final product.
                                </p>
                            </div>
                            <div>
                                <h1 className={'dark:text-gray-400 text-2xl font-extrabold pb-4 w-full text-center'}>- Development</h1>
                                <p className={'dark:text-gray-100 leading-8 text-xl text-center'}>
                                    During the development phase, our team works on creating the software using agile methodologies.
                                    This includes regular meetings, code reviews, and testing to ensure that the software is functioning as intended.
                                    Our developers are encouraged to collaborate and communicate with each other to ensure that the final product is of high quality.
                                </p>
                            </div>
                            <div>
                                <h1 className={'dark:text-gray-400 text-2xl font-extrabold pb-4 w-full text-center'}>- Testing and QA</h1>
                                <p className={'dark:text-gray-100 leading-8 text-xl text-center'}>
                                    After the software is developed, it is thoroughly tested and reviewed for any bugs or issues.
                                    Our QA team works to ensure that the software meets the requirements and is free of any defects.
                                    We also use automated testing tools to speed up the process and make it more efficient.
                                </p>
                            </div>
                            <div>
                                <h1 className={'dark:text-gray-400 text-2xl font-extrabold pb-4 w-full text-center'}>- Deployment and Maintenance</h1>
                                <p className={'dark:text-gray-100 leading-8 text-xl text-center'}>
                                    Once the software is deemed ready for deployment, it is released to the client.
                                    We provide ongoing support and maintenance to ensure that the software continues to function as intended and that any issues are addressed promptly.
                                    Our team is always available to provide support and make any necessary updates or changes.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'h-auto w-full py-36 space-y-20 text-gray-600'} id={'AboutUs'}>
                    <div className={'flex justify-center w-full px-4'}>
                        <div className={'w-4/6 max-lg:w-full'}>
                            <h1 className={'dark:text-gray-400 text-2xl font-extrabold pb-4 w-full text-center'}>- Our Mission</h1>
                            <p className={'text-2xl text-center w-full'}>
                                Our mission is to deliver innovative software solutions that make the world a better place.
                                We strive to create products that empower individuals and organizations to achieve their goals and to make a positive impact on society.
                                We are committed to providing exceptional customer service and to delivering high-quality products that exceed expectations.
                                We believe that technology has the power to change the world for the better,
                                and we are dedicated to using our expertise and resources to create solutions that make a meaningful difference.
                            </p>
                        </div>
                    </div>
                    <div className={'flex justify-center w-full px-4'}>
                        <div className={'w-4/6  max-lg:w-full'}>
                            <h1 className={'dark:text-gray-400 text-2xl font-extrabold pb-4 w-full text-center'}>- Our Vision</h1>
                            <p className={'text-2xl text-center w-full'}>
                                Our vision is to create sustainable and impactful technology that delivers exceptional value to our customers.
                                We strive to build innovative and sustainable solutions that are designed to have a positive impact on the lives of our users and the environment.
                                We believe in creating long-term value for our customers by delivering exceptional products and services that exceed their expectations.
                                Our goal is to be a leader in the industry by providing sustainable and impactful technology that makes a difference in the world.
                            </p>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    )
}
