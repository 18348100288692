import logo from '../assets/tollesoft.png';
import {useState} from "react";
import {useNavigate} from "react-router-dom";

export const Header = () => {
    const navigate = useNavigate();

    const [productMenu, showProductMenu] = useState<boolean>(false);
    const scrollToSection = (sectionId: any) => {
        const element = document.getElementById(sectionId);
        if (element) {
            element.scrollIntoView({behavior: "smooth"})
        }
    }

    const route = (url: string) => {
        showProductMenu(!productMenu)
        navigate(url);
    }

    return (
        <>
            <div className="w-full h-auto backdrop-blur-md sticky top-0 z-20 border-b dark:border-b-gray-800">
                <div className="px-10 h-16 w-full flex items-center justify-between">
                    <div className="flex items-center space-x-1 dark:text-white">
                        <div className="font-extrabold text-3xl cursor-pointer">
                            <img alt={'logo'} src={logo} style={{ maxWidth: '120px' }} />
                        </div>
                    </div>
                    <div className="flex items-center space-x-3 dark:text-white text-gray-700 lg:block max-lg:hidden ">
                        <span onClick={() => scrollToSection('whatWeDo')} className="pl-3 cursor-pointer">What we do</span>
                        <span onClick={() => scrollToSection('OurProcess')} className="pl-3 cursor-pointer">Our process</span>
                        <div className={'inline pl-3 relative cursor-pointer'}>
                            <span onClick={() => showProductMenu(!productMenu)}>Products</span>
                            {
                                productMenu &&
                                <div className={'absolute rounded top-8 right-0 dark:text-white dark:bg-slate-800 bg-white shadow-xl min-w-[200px] border border-slate-900'}>
                                    <div onClick={() => route('/fidbak')} className={'p-2'}>FID BAK</div>
                                    {/*<div className={'p-2'}>TOLLECARE</div>*/}
                                </div>
                            }
                        </div>
                        <span onClick={() => scrollToSection('AboutUs')} className="pl-3 cursor-pointer">About us</span>
                    </div>
                </div>
            </div>
        </>
    )
}
