import dashboard from "../assets/dashboard.png";
import React, {useEffect, useLayoutEffect} from "react";
import logo from "../assets/tollesoft.png";
import {useLocation, useNavigate} from "react-router-dom";
import {Footer} from "../shared/footer";

export default function FidbakProduct() {
    useEffect(() => {
        const element = document.getElementById('top');
        if (element) {
            element.scrollIntoView({behavior: "smooth"})
        }
    }, [])
    const navigate = useNavigate();
    const route = (url: string) => {
        navigate(url);
    }

    return(
        <>
            <div className="w-full h-auto backdrop-blur-md sticky top-0 z-20 border-b dark:border-b-gray-800">
                <div className="px-10 h-16 w-full flex items-center justify-between">
                    <div className="flex items-center space-x-1 dark:text-white">
                        <div onClick={() => route('/')} className="font-extrabold text-3xl cursor-pointer">
                            {/*<img alt={'logo'} src={logo} style={{ maxWidth: '120px' }} />*/}
                            <label className="text-2xl font-bold block">
                                <label className="text-yellow-400">FID</label>
                                <label className="text-blue-400">BAK</label>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div id={'top'} className="flex flex-col w-full h-full">
                <div className={'flex justify-center w-full'}>
                    <div className="max-xl:w-full space-y-4">
                        <div className={'lg:grid lg:grid-cols-2 lg:gap-4 h-auto relative w-full py-36 lg:px-2'}>
                            <div className={'lg:pl-56 lg:pr-8 max-lg:pb-6 relative'}>
                                <h1 className={'dark:text-gray-400 text-slate-600 text-6xl font-extrabold pb-4 max-lg:text-center'}>The key to success is listening: Collect customer feedback with us.</h1>
                                <p className={'dark:text-gray-100 leading-8 text-xl max-lg:text-center'}>
                                    Your customers hold the key to your success, and we've got the platform to unlock it. Join us today and start listening to their feedback.
                                    With our powerful insights, you'll be able to take your business to new heights!
                                </p>
                            </div>
                            <div className={'max-lg:flex max-lg:justify-center relative'}>
                                <div className={'relative'}>
                                    <img src={dashboard} className={'max-w-full z-20 h-auto rounded-tl-xl rounded-bl-xl border-r-0 border-black border-8'} alt={''}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'flex justify-center w-full'}>
                    <div className={'max-xl:w-full xl:w-10/12 2xl:w-4/6 space-y-4'}>
                        <div className={'flex justify-center items-center space-x-2'}>
                            <div className={'text-center'}>
                                <span className={'block text-3xl font-bold pb-8'}> Why Choose FIDBAK ?</span>
                                <p className={'text-xl text-gray-500'}>
                                    Using our feedback software helps businesses unlock the key to their success by listening to their customers, gaining powerful insights, and reaching new heights.
                                </p>
                            </div>
                        </div>
                        <div className={'grid lg:grid-cols-3 gap-10 relative w-full py-16 px-2'}>
                            <div className={'p-6 shadow-2xl rounded-xl'}>
                                <div className={'w-full text-center'}>
                                    <h3 className={'font-bold pb-10 text-3xl text-gray-800'}>Improve customer satisfaction</h3>
                                    <span className={'text-xl text-gray-500'}>
                                        A feedback software allows businesses to listen to their customers and address their concerns, leading to better customer experiences and increased satisfaction.
                                    </span>
                                </div>
                            </div>
                            <div className={'p-6 shadow-2xl rounded-xl'}>
                                <div className={'w-full text-center'}>
                                    <h3 className={'font-bold pb-10 text-3xl text-gray-800'}>Identify areas for improvement</h3>
                                    <span className={'text-xl text-gray-500'}>
                                        By collecting and analyzing feedback, businesses can identify areas where they can improve their products, services, and operations, ultimately leading to increased efficiency and profitability.
                                    </span>
                                </div>
                            </div>
                            <div className={'p-6 shadow-2xl rounded-xl'}>
                                <div className={'w-full text-center'}>
                                    <h3 className={'font-bold pb-10 text-3xl text-gray-800'}>Stay competitive</h3>
                                    <span className={'text-xl text-gray-500'}>
                                        By using a feedback software, businesses can stay on top of trends, understand customer preferences, and respond quickly to changes in the market, helping them stay ahead of the competition.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'flex justify-center w-full py-16'}>
                    <div className={'max-xl:w-full xl:w-10/12 2xl:w-4/6 space-y-4'}>
                        <div className={'flex justify-center items-center space-x-2'}>
                            <div className={'text-center'}>
                                <span className={'block text-5xl text-gray-800 font-bold pb-8'}> Book A Demo</span>
                                <p className={'text-3xl text-gray-500'}>
                                    Get intouch, book a demo and start your journey of listening to your customers, gaining powerful insights, and reaching new heights.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    )
}
